import { api } from "../utils/Api/apiClient";
import { ABACATE_BACKEND_URL } from "../settings";

const createTicket = async (data) => {
  const url = `${ABACATE_BACKEND_URL}/api/tickets/v1/ticket`;
  return await api.post(url, data);
};

const getTickets = async ({ subjectId, status, externalCode, page }) => {
  const url = `${ABACATE_BACKEND_URL}/api/tickets/v1/ticket`;
  return await api.get(url, {
    params: {
      subjectId,
      status,
      externalCode,
      page,
    },
  });
};

const getTicketById = async ({ ticketId }) => {
  const url = `${ABACATE_BACKEND_URL}/api/tickets/v1/ticket/${ticketId}`;
  return await api.get(url);
};

const updateTicket = async ({ ticketId, ticketData }) => {
  const url = `${ABACATE_BACKEND_URL}/api/tickets/v1/ticket/${ticketId}`;
  return await api.put(url, ticketData);
};

const deleteTicket = async ({ ticketId }) => {
  const url = `${ABACATE_BACKEND_URL}/api/tickets/v1/ticket/${ticketId}`;
  return await api.delete(url);
};

const TicketService = {
  createTicket,
  getTickets,
  getTicketById,
  updateTicket,
  deleteTicket,
};

export default TicketService;
